body {
background-image: url('./assets//background2.webp');
background-size: cover;
    
 }

 @media screen and (max-width: 800px) {
     body {
         background-size: unset;
     }
 }