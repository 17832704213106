$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -20px;
  font-size: 12px;
  font-weight: 600;
  color: $main-color;

}
.group {
  // border: 1px solid blue;
  position: relative;
  margin: 30px 0 0;
  
  .show-password {
    position: absolute;
    right: 1px;
    top: 0px;
    
  }
  .fa-eye  {
    color: lightgrey;
    font-size: 24px;
    position: absolute;
    right: 1px;
    top:5px;
  }
  .fa-eye-slash {
    color: lightgrey;
    font-size: 24px;
    right: 1px;
    top:5px;
  }

  .form-input {
    background: none;
    background-color: white;
    color: $main-color;
    font-size: 18px;
    padding: 5px 0px 5px 0px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $sub-color;
    margin: 0 0;

    &:focus {
      outline: none;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();

    }
    &:disabled {
      color: gray;
      background-color: lightgray;
    }
  }
  input[role='labelOpen'] ~ .form-input-label {
    transition: none;
    @include shrinkLabel();
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  textarea[type='textarea'] {
    height: 100px;
    width: 100%;
    resize: none;
    font-size: 16px;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  textarea[role='labelOpen'] ~ .form-input-label {
    transition: none;
    @include shrinkLabel();
  }
  
  .form-input-label {
    text-align: right;
    color: $sub-color;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    right: 0px;
    top: 5px;
    transition: 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
