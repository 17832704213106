$backColor: #222;
$color: #3FB449;

.posts-tab-container {
  // background-color: #3FB449;
    display: flex;
    flex-direction: row;
    // flex-grow: 2;
    direction: rtl;
    // border: 1px solid red;
    // border-bottom: none;
    // box-shadow: 10px 10px 10px gray;
    // height: 67.7vh;
    width: 50%;
    // height: 60vh;
    position: relative;
    margin-top: 18px;
    // background-color: $backColor;
  @media screen and (max-width: 1024px) {
    width: 90%;
    margin-top: 40px;

  }


.react-tabs {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    position: relative;
    // border: 1px solid blue;
    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
    }
    // flex-grow: 2;



    &__tab-list {
    //   border: 1px solid $backColor;
      margin: 0 0 -1px;
      padding: 0;
      padding-top: 2px;
      // background-color: white; 
      border-bottom: 1px solid black;

    }
  
    &__tab {
      display: inline-block;
      border: 1px solid transparent;
      // border-bottom: none;  
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 5px 12px;
      cursor: pointer;
      border-color: #000;
      border-radius: 5px 5px 0 0 ;
      background-color: #F3F3F3;


  
      &--selected {
        background: $backColor;
        border-color: #222;
        color: $color;
        // border-bottom: 1px solid grey;
        font-weight: bold;
        border-radius: 5px 5px 0 0; 
      }
  
      &--disabled {
        color: GrayText;
        cursor: default;
      }
      &:hover{
        border-radius: 5px 5px 0 0;
          background-color: black;
          color: $color;
          // font-weight: bold;
      }
  
      &:focus {
        box-shadow: 0 0 5px hsl(208, 99%, 50%);
        border-color: hsl(208, 99%, 50%);
        outline: none;
  
        &:after {
          content: "";
          position: absolute;
          height: 5px;
          left: -4px;
          right: -4px;
          bottom: -5px;
          background: #fff;
        }
      }
    }
  
    &__tab-panel {
      display: none;
      direction: ltr;
      position: relative;
      height: auto;
      // width: 100%;
      // border: 1px solid red;
  
      &--selected {
        display: flex;
        direction: ltr;
        
      }
    }
  }
}