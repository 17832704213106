$main-color: #000000;

.select-customer-label {
    text-align: right;
    color: $main-color;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    right: 0px;
    top: -20px;
    transition: 300ms ease all;
    // border: 1px solid springgreen;
    }

    