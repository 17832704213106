.tabulator .tabulator-header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-bottom: none;
    background-color: black !important;
    /* border-radius: 10px 10px 0px 0px; */
    color: #fff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    /* -moz-user-select: none; */
    /* -khtml-user-select: none; */
    /* -webkit-user-select: none; */
    /* -o-user-select: none; */
  }
  .tabulator, .tabulator-header, .tabulator-tableHolder{
    overflow-x:hidden !important;
}  
  .tabulator-col-sorter {
      right: unset !important;
      left: 0px;  
  }
  .tabulator-col .tabulator-headers .tabulator-rtl .tabulator-header {
    background-color: black !important;
  }

  .tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
    position: relative;
    box-sizing: border-box;
    margin-top: 2px;
    width: 100%;
    text-align: center;
  }

  .tabulator {
      border:1px solid #000 ;
      /* border: none; */
      /* box-shadow: 0px 10px 10px grey ; */
  }

  .tabulator .tabulator-footer {
    padding: 5px 10px;
    padding-top: 8px;
    border-top: 3px solid #3FB449;
    background-color: #222;
    text-align: center;
    color: #222;
    font-weight: bold;
    white-space: nowrap;
    -ms-user-select: none;
        user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  
    .tabulator .tabulator-footer .tabulator-paginator label {
      color: #fff;
    }
    
    .tabulator .tabulator-footer .tabulator-page-size {
      display: inline-block;
      margin: 0 5px;
      padding: 2px 5px;
      border: 1px solid #aaa;
      border-radius: 3px;
    }
    
    .tabulator .tabulator-footer .tabulator-pages {
      margin: 0 7px;
    }
    
    .tabulator .tabulator-footer .tabulator-page {
      display: inline-block;
      margin: 0 2px;
      padding: 2px 5px;
      border: 1px solid #aaa;
      border-radius: 3px;
      background: #fff;
      color: #222;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
    }
    
    .tabulator .tabulator-footer .tabulator-page.active {
      color: #3FB449;
    }
    
    .tabulator .tabulator-footer .tabulator-page:disabled {
      opacity: .5;
    }
    
    .tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
    }
  
    .tabulator-row.tabulator-selectable:hover {
      background-color: #222;
      color: #3FB449;
      font-weight: bold;
      cursor: pointer;
    }
  
    .tabulator-row.tabulator-selected {
    background-color:lightgreen;
    color: #000;
    font-weight: bold;
  }
  
  .tabulator-row.tabulator-selected:hover {
    background-color: black;
    color: #3FB449;
    cursor: pointer;
  }

  .tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
    cursor: pointer;
    /* color: #fff;
    background: #1D68CD; */
    color: #3FB449;
    background: #222;
    font-weight: bold;
  }

  .table-actions {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: right;
    border: 1px solid black;
    border-bottom: none;
    border-top: none;
    background-color: #222;
    height: 35px;
    padding: 0 5px;
    
  }
  .table-action {
    display: flex;
    flex-direction: row;
    align-items:center ;
    justify-content: space-between;
    border: 1px solid gray;
    background-color: white;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    height: 15px;
    
  }
  .table-action:hover {
    color: #3FB449;
    background-color: black;
    border-color: white;
    cursor: pointer;
    /* font-size: 16px; */
    /* font-weight: bolder; */
  }