.tabulator .tabulator-header {
    position: relative;
    box-sizing: border-box;
    /* width: 100%; */
    border-bottom: none;
    background-color: black !important;
    /* border-radius: 10px 10px 0px 0px; */
    color: #fff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    /* -moz-user-select: none; */
    /* -khtml-user-select: none; */
    /* -webkit-user-select: none; */
    /* -o-user-select: none; */
  }
  .tabulator-container {
    width:36vw;
  }
    
    .tabulator-col-sorter {
      right: unset !important;
      left: 0px;  
  }
  .tabulator-col .tabulator-headers .tabulator-rtl .tabulator-header {
    background-color: black !important;
  }

  .tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
    position: relative;
    box-sizing: border-box;
    margin-top: 2px;
    width: 100%;
    text-align: center;
  }

  .tabulator {
      border:1px solid #000 ;
      /* box-shadow: 0px 10px 10px grey ; */
  }

  .main-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0px;
      /* width: 50vw; */
      /* border: 1px solid blue; */
  }
  .main-data-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-bottom: 25px; */
    /* overflow: auto; */
    position: relative;
    /* overflow: scroll; */
    /* border: 1px solid red; */

  }

  .display-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    /* background-color: #f3f3f3; */
    /* box-shadow: 0px 10px 10px grey ; */
    /* color: red; */
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
    padding: 0;
  }
  .spacer {
      width: 50px;
  }
  .main-filter-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid blueviolet; */
    width: 99vw;
    /* height: 50vh; */
  }
  .filter-form {
        display: flex;
        flex-direction: row-reverse; 
        justify-content: right;
        align-items: center;
        align-content: center;
        border: 1px solid black;
        background-color: #f3f3f3;
        border-top: none;
        box-shadow: 0px 10px 10px grey ;
        padding: 0;
        padding-bottom: 25px;
        /* margin-bottom: 20px; */
        width: 100%;
        /* width: 60vw; */
    }
    .filter-item {
        width: 18vw;
        padding: 0;
        margin: 0 25px 0 0;
  }
  .filter-clear {
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 22vw;
        /* border: 1px solid black; */
}
  .form-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #000;
      color: #3FB449;
      width: 100%;
      height: 25px;
      padding: 0 0;
      margin: 0;
      border: 1px solid black ;
      
  }
  .page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F3;
    color: black;
    width: 100vw;
    height: 25px;
    padding: 15px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid black ;
    box-shadow: 0px 10px 10px grey ;
    /* position: sticky; */
    /* top: 81px; */
}
  .table-actions {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid black;
    border-bottom: none;
    background-color: #222;
    height: 35px;
    padding: 0;
    
  }

  .table-stats {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    /* border-bottom: none; */
    background-color: black;
    color: #3FB449;
    height: 35px;
    padding: 0;
    border-radius: 5px 5px 0 0;
    
  }

  .table-action {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    background-color: white;
    padding: 5px 25px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    height: 15px;
  }
  .table-action:hover {
    color: #3FB449;
    background-color: black;
    border-color: white;
    cursor: pointer;
    /* font-size: 16px; */
    /* font-weight: bolder; */
  }

  .clear-form-button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      width: 80px;
      height: 38px;
      border: 1px solid black;
      border-radius: 3px;
      margin-right:10px ;
      margin-top: 2px;
      cursor: pointer;
  }
  .clear-form-button:hover {
    background-color: black;
    color: #3FB449;
    font-weight: bolder;
  }
 /* tabulator paginator */

 .tabulator .tabulator-footer {
  padding: 5px 10px;
  padding-top: 8px;
  border-top: 3px solid #3FB449;
  background-color: #222;
  text-align: center;
  color: #222;
  font-weight: bold;
  white-space: nowrap;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

  .tabulator .tabulator-footer .tabulator-paginator label {
    color: #fff;
  }
  
  .tabulator .tabulator-footer .tabulator-page-size {
    display: inline-block;
    margin: 0 5px;
    padding: 2px 5px;
    border: 1px solid #aaa;
    border-radius: 3px;
  }
  
  .tabulator .tabulator-footer .tabulator-pages {
    margin: 0 7px;
  }
  
  .tabulator .tabulator-footer .tabulator-page {
    display: inline-block;
    margin: 0 2px;
    padding: 2px 5px;
    border: 1px solid #aaa;
    border-radius: 3px;
    background: #fff;
    color: #222;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
  
  .tabulator .tabulator-footer .tabulator-page.active {
    color: #3FB449;
  }
  
  .tabulator .tabulator-footer .tabulator-page:disabled {
    opacity: .5;
  }
  
  .tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  .tabulator-row.tabulator-selectable:hover {
    background-color: #222;
    color: #3FB449;
    cursor: pointer;
  }

  .tabulator-row.tabulator-selected {
  background-color:lightgreen;
}

.tabulator-row.tabulator-selected:hover {
  background-color: black;
  color: #3FB449;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .main-data-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .page-header {
    margin: unset;
    margin-top: 0;
    justify-content: space-around;
  }
  .tabulator-container {
    width: 90%;
  }
}